import React, { Component } from "react"

import SEO from "../components/SEO"
import NewsletterSignUpForm from "../components/MC/NewsletterSignUp"

import { Container, Row, Col } from "reactstrap"

class NewsletterPage extends Component {
  render() {
    return (
      <>
        <SEO 
          title="brillea newsletter | Good marketing into your inbox"
        />

        <Container className={"section_large section_center_xxsmall"}>
          <Row>
            <Col>
              <h1 className="text-center">brillea marketing tricks</h1>
              <h2 className={"text-center h3 mb-4 grey_color"}>
                Good marketing into your inbox
              </h2>
              <div className="card">
                <div className="card-body">
                  <NewsletterSignUpForm
                    Groups={{
                      MC_GROUP_EXPERTS: false,
                      MC_GROUP_USERS: false,
                      MC_GROUP_NEWS: true,
                    }}
                  />
                </div>
              </div>
              <p className="mt-4 text-center">we don't bite!</p>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
export default NewsletterPage
